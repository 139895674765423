$color-primary: #222222;
$color-secondary: #444;
$color-inverse: white;

$color-one: #f9188f;
$color-two: #16B3CC;
$color-three: #FFEC4F;
$color-four: #403E33;

$color-highlight-one: #B3145E;
$color-highlight-two: #1D9DB3;
$color-detail: #159603;

$text-shadow: 2px 2px 4px rgba(0, 0, 0, 0.2);
$shadow-color: rgba(0, 0, 0, 0.2);

$border-radius-small: 4px;
$border-radius-large: 14px;

$screen-large: 1400px;
$screen-medium: 1000px;
$screen-small: 840px;
$screen-tiny: 600px;
