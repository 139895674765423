@import url(https://fonts.googleapis.com/css?family=Cinzel+Decorative|Montserrat|Share+Tech+Mono&display=swap);
.color--primary {
  color: #222222; }

.color--secondary {
  color: #444; }

.color-inverse {
  color: white; }

.color--one {
  color: #f9188f; }

.color--two {
  color: #16B3CC; }

.color--three {
  color: #FFEC4F; }

.color--four {
  color: #403E33; }

.align--left {
  text-align: left; }

.align--right {
  text-align: right; }

.align--center {
  text-align: center; }

form label {
  font-family: "Cinzel Decorative", serif;
  font-size: 1.2em;
  font-weight: bold;
  margin: 1em 0;
  display: block; }

form input {
  padding: .8em 1em;
  font-family: "Montserrat", sans-serif;
  font-size: 12px;
  line-height: 1.6;
  background: #222222;
  color: white;
  width: 100%;
  border: 2px solid #444;
  border-radius: 4px;
  margin-top: .4em; }
  form input:focus {
    border-color: #B3145E; }

form textarea {
  height: 20em;
  padding: .8em 1em;
  resize: none;
  font-family: "Montserrat", sans-serif;
  font-size: 12px;
  line-height: 1.6;
  background: #222222;
  color: white;
  width: 100%;
  border: 2px solid #444;
  border-radius: 4px;
  margin-top: .4em; }
  form textarea:focus {
    border-color: #B3145E; }

button {
  color: white;
  font-family: "Montserrat", sans-serif;
  text-transform: uppercase;
  background: #222222;
  border: 2px solid #444;
  border-radius: 4px;
  cursor: pointer;
  padding: 1em 2em;
  -webkit-transition: all .3s ease-out;
  transition: all .3s ease-out; }
  button:hover {
    color: #B3145E;
    border-color: #B3145E; }
  button.button--link {
    color: #1D9DB3;
    font-weight: bold;
    text-transform: unset;
    background: transparent;
    padding: 3px 2px;
    border-radius: 0;
    border: 0; }
    button.button--link:hover {
      color: #B3145E; }
  button svg {
    margin-right: 4px; }

.content__blocks {
  display: inline-block;
  margin: 0 auto;
  width: 58em;
  max-width: 100%; }
  @media (max-width: 600px) {
    .content__blocks {
      width: auto; } }

.block__content {
  width: 40em;
  max-width: 100%;
  background: white;
  padding: 2em 8em 5em;
  border-radius: 4px;
  box-sizing: content-box;
  box-shadow: 0 0 20px rgba(0, 0, 0, 0.2); }
  @media (max-width: 840px) {
    .block__content {
      padding: 2em 10% 4em;
      width: auto; } }
  @media (max-width: 600px) {
    .block__content {
      padding: 1em 2em 3em;
      border-left: 0 !important;
      border-right: 0 !important; } }

.block#experience .block__content {
  border: #f9188f solid 15px; }

.block#experience:after {
  content: "";
  display: block;
  background: #222222;
  width: 8px;
  height: 4em;
  margin: 0 auto; }

.block#education .block__content {
  border: #16B3CC solid 15px; }

.block#education:after {
  content: "";
  display: block;
  background: #222222;
  width: 8px;
  height: 4em;
  margin: 0 auto; }

.block#freetime .block__content {
  border: #FFEC4F solid 15px; }

.block#freetime:after {
  content: "";
  display: block;
  background: #222222;
  width: 8px;
  height: 4em;
  margin: 0 auto; }

.block#projects .block__content {
  border: #403E33 solid 15px; }

@media (min-width: 1401px) {
  .block#experience .block__content {
    margin-left: -8em; }
  .block#education .block__content {
    margin-left: 8em; }
  .block#freetime .block__content {
    margin-left: -14em; }
  .block#projects .block__content {
    margin-left: -3em; } }

@media (min-width: 1001px) {
  .block#experience .block__content {
    margin-left: -4vw; }
  .block#education .block__content {
    margin-left: 6vw; }
  .block#freetime .block__content {
    margin-left: -6vw; }
  .block#projects .block__content {
    margin-left: 4vw; } }

/* Experience Block */
#experience .experience__filter {
  border: 2px solid #444;
  padding: 2em 1em;
  margin-top: 4em;
  border-radius: 4px; }
  @media (max-width: 600px) {
    #experience .experience__filter {
      border: 0;
      padding: 0;
      margin-top: 3em; } }
  #experience .experience__filter .filter__container {
    margin-bottom: 1em; }
    #experience .experience__filter .filter__container input {
      display: none; }
    #experience .experience__filter .filter__container label {
      font-size: 80%;
      text-transform: uppercase;
      white-space: nowrap;
      border: 1px solid #444;
      border-radius: 2px;
      padding: 4px 6px;
      margin: 6px 4px;
      display: inline-block;
      cursor: pointer;
      -webkit-transition: all .1s ease-in;
      transition: all .1s ease-in; }
      @media (max-width: 600px) {
        #experience .experience__filter .filter__container label {
          font-size: 100%; } }
      #experience .experience__filter .filter__container label svg {
        font-size: 1.4em;
        margin: -.1em 4px -.1em -.1em; }
      #experience .experience__filter .filter__container label.is--selected {
        color: white;
        background: -webkit-gradient(linear, left top, left bottom, from(#444), to(#222222));
        background: linear-gradient(180deg, #444, #222222); }
      #experience .experience__filter .filter__container label:hover:not(.is--selected) {
        color: white;
        background: #444;
        border-color: #222222; }
  #experience .experience__filter .tags {
    display: flex;
    flex-direction: row;
    flex-wrap: wrap; }
    #experience .experience__filter .tags .tag {
      font-size: 90%;
      background: #222222;
      color: white;
      padding: 2px 8px;
      margin: 6px 3px;
      border-radius: 4px;
      display: flex;
      flex-direction: row;
      align-items: center; }
      @media (max-width: 600px) {
        #experience .experience__filter .tags .tag {
          font-size: 100%; } }
    #experience .experience__filter .tags .tag__level {
      height: 1.2em;
      margin-left: .3em;
      width: 45px;
      background: #444;
      border: 1px solid #222222; }
    #experience .experience__filter .tags .level__indicator {
      display: inline-block;
      background: -webkit-gradient(linear, left top, right top, from(#1cc804), color-stop(90%, #159603));
      background: linear-gradient(90deg, #1cc804 0%, #159603 90%);
      width: 6px;
      height: 100%;
      margin-right: 3px;
      border-radius: 1px; }
      #experience .experience__filter .tags .level__indicator:last-child {
        margin-right: 0; }

/* Education Block */
#education .info__block {
  display: flex;
  flex-direction: row; }
  #education .info__block .image {
    margin: 1.5em 1em 0 0; }
    @media (max-width: 600px) {
      #education .info__block .image {
        display: none; } }
    #education .info__block .image img {
      height: 12em; }
  #education .info__block p {
    margin: 0.5em 0; }

/* Freetime Block */
#freetime .image__gallery {
  display: flex;
  flex-direction: row; }
  #freetime .image__gallery .image {
    width: 33%;
    margin: 5px; }
    #freetime .image__gallery .image:first-child {
      margin-left: 0; }
    #freetime .image__gallery .imagelast-child {
      margin-right: 0; }
    #freetime .image__gallery .image img {
      width: 100%;
      cursor: -webkit-zoom-in;
      cursor: zoom-in; }

#freetime .image__lightbox {
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background: rgba(0, 0, 0, 0.85);
  text-align: center;
  z-index: 100;
  display: none;
  cursor: -webkit-zoom-out;
  cursor: zoom-out; }
  #freetime .image__lightbox .image {
    margin: 3vh auto;
    max-width: 100%;
    max-height: 100%;
    display: inline-block;
    border: 2px solid #222222;
    overflow: hidden; }
    @media (max-width: 600px) {
      #freetime .image__lightbox .image {
        padding: 1em;
        margin: 5vw auto; } }
    #freetime .image__lightbox .image img {
      display: block;
      max-width: 90vw;
      max-height: 90vh;
      padding: 2em 3em;
      background: white; }
      @media (max-width: 600px) {
        #freetime .image__lightbox .image img {
          padding: 1em; } }

.bookmarks {
  position: fixed;
  top: 20%;
  left: 0;
  z-index: 10;
  width: 3em; }
  @media (max-width: 600px) {
    .bookmarks {
      display: none; } }
  .bookmarks a {
    font-family: "Cinzel Decorative", serif;
    font-size: 1.3em;
    font-weight: bold;
    text-align: right;
    line-height: 3em;
    padding: 0 1em;
    box-sizing: content-box;
    width: 8em;
    display: block;
    margin-bottom: 1em;
    margin-left: -8.5em;
    border-top-right-radius: 14px;
    border-bottom-right-radius: 14px;
    box-shadow: 1px 3px 5px rgba(0, 0, 0, 0.2);
    text-decoration: none;
    -webkit-transition: all .2s ease-in;
    transition: all .2s ease-in; }
    @media (max-width: 840px) {
      .bookmarks a {
        margin-left: -7.5em; } }
    .bookmarks a:link, .bookmarks a:visited {
      color: transparent;
      border: none; }
    .bookmarks a:hover, .bookmarks a:focus {
      margin-left: 0;
      color: #222222; }
    .bookmarks a:nth-child(1) {
      background: #f9188f; }
      .bookmarks a:nth-child(1):hover {
        color: white; }
    .bookmarks a:nth-child(2) {
      background: #16B3CC; }
      .bookmarks a:nth-child(2):hover {
        color: white; }
    .bookmarks a:nth-child(3) {
      background: #FFEC4F; }
    .bookmarks a:nth-child(4) {
      background: #403E33; }
      .bookmarks a:nth-child(4):hover {
        color: white; }
    .bookmarks a:nth-child(5) {
      background: #222222; }
      .bookmarks a:nth-child(5):hover {
        color: white; }

.background--four footer {
  color: #222222; }

footer {
  color: white;
  text-align: center;
  width: 42em;
  max-width: 100%;
  padding: 4em 2em 0; }
  footer .contact.contact__links {
    margin: 1em 0 4em; }
    footer .contact.contact__links .links {
      height: 4em;
      display: flex;
      flex-direction: row;
      align-items: center;
      flex-wrap: wrap; }
    footer .contact.contact__links .link {
      flex: 1 1;
      display: block;
      white-space: nowrap;
      padding: 5px; }
    footer .contact.contact__links a:link, footer .contact.contact__links a:visited {
      font-size: 1.2em;
      margin: -.2em;
      color: inherit;
      text-decoration: none;
      display: inline-block;
      border-bottom: 3px solid transparent;
      -webkit-transition: all .3s ease;
      transition: all .3s ease; }
    @media (min-width: 601px) {
      footer .contact.contact__links a:hover {
        font-size: 1.4em;
        border-color: #444; } }
    footer .contact.contact__links a svg {
      margin-right: .3em; }

.contact.contact__icons {
  text-align: right;
  padding: 1em 10% 0; }
  .contact.contact__icons .links {
    height: 4em;
    display: inline-flex;
    align-items: center;
    flex-direction: row; }
  .contact.contact__icons a:link, .contact.contact__icons a:visited {
    color: #222222;
    border: none;
    margin: 4px; }
  .contact.contact__icons a:hover {
    color: #444; }
    .contact.contact__icons a:hover svg {
      height: 3.2em; }
  .contact.contact__icons a svg {
    width: 2.8em;
    height: 2.6em;
    -webkit-transition: all .2s ease-out;
    transition: all .2s ease-out; }
  .contact.contact__icons a span {
    display: none; }

* {
  box-sizing: border-box; }

html, body {
  margin: 0;
  padding: 0;
  scroll-behavior: smooth; }

body {
  color: #222222;
  font-family: "Montserrat", sans-serif;
  font-size: 14px;
  line-height: 1.8; }
  @media (max-width: 840px) {
    body {
      font-size: 12px; } }
  body.background--one {
    background: -webkit-gradient(linear, left top, left bottom, from(#f9188f), to(#ce0570));
    background: linear-gradient(180deg, #f9188f, #ce0570);
    background-size: cover;
    background-attachment: fixed; }
  body.background--two {
    background: -webkit-gradient(linear, left top, left bottom, from(#16B3CC), to(#108395));
    background: linear-gradient(180deg, #16B3CC, #108395);
    background-size: cover;
    background-attachment: fixed; }
  body.background--three {
    background: -webkit-gradient(linear, left top, left bottom, from(#FFEC4F), to(#ffe512));
    background: linear-gradient(180deg, #FFEC4F, #ffe512);
    background-size: cover;
    background-attachment: fixed; }
  body.background--four {
    background: -webkit-gradient(linear, left top, left bottom, from(white), to(#e0e0e0));
    background: linear-gradient(180deg, white, #e0e0e0);
    background-size: cover;
    background-attachment: fixed; }
  body.background--five {
    background: -webkit-gradient(linear, left top, left bottom, from(#444), to(#252525));
    background: linear-gradient(180deg, #444, #252525);
    background-size: cover;
    background-attachment: fixed; }

h1, h2, h3, h4, h5, h6 {
  font-family: "Cinzel Decorative", serif;
  line-height: 1.4;
  text-shadow: 2px 2px 4px rgba(0, 0, 0, 0.2); }

h1 {
  font-size: 3.5em; }

h2 {
  font-size: 2.8em;
  margin: .8em 0 .5em; }

h3 {
  font-size: 1.4em; }

p, ul {
  line-height: 2; }

a:link, a:visited {
  color: #222222;
  text-decoration: none;
  border-bottom: 2px solid #1D9DB3;
  -webkit-transition: all .3s ease-out;
  transition: all .3s ease-out; }

a:hover {
  border-color: #B3145E; }

ul {
  margin: 2em 0; }
  @media (max-width: 600px) {
    ul {
      padding: 0 1em; } }
  ul li {
    list-style: none; }
    ul li:before {
      content: "\25CF";
      color: #B3145E;
      padding-right: 1.2em; }
      @media (max-width: 600px) {
        ul li:before {
          padding-right: .8em; } }

code {
  color: #B3145E;
  font-family: "Share Tech Mono", monospace; }

header {
  color: #222222;
  text-align: center;
  width: 100%;
  margin-bottom: 2em; }
  header .header {
    display: inline-flex;
    flex-direction: row; }
    @media (max-width: 600px) {
      header .header {
        -webkit-transform: scale(0.8);
                transform: scale(0.8); } }
    header .header img {
      height: 12.5em;
      margin-top: 2em;
      margin-right: 1em; }
    header .header #given-name {
      font-size: 2.3em;
      line-height: 1;
      display: block; }
    header .header #last-name {
      display: block; }
  header .title {
    font-size: 1.6em;
    font-weight: bold;
    text-transform: uppercase;
    line-height: 1em;
    color: #444;
    margin: -0.5em 0 2em; }
    @media (max-width: 600px) {
      header .title {
        -webkit-transform: scale(0.8);
                transform: scale(0.8);
        margin-top: -1em; } }

.content {
  display: flex;
  align-items: center;
  flex-direction: column;
  background: transparent url("/images/dotted-background.svg") repeat-x;
  background-size: auto 90%;
  background-attachment: fixed;
  padding: 0 0 6em; }

button.back-to-top {
  position: fixed;
  bottom: 3%;
  right: 2%;
  cursor: pointer;
  font-size: 2em;
  border: 3px solid #222222;
  background: rgba(255, 255, 255, 0.1);
  color: #222222;
  width: 2.5em;
  height: 2.5em;
  border-radius: 50%;
  opacity: 0.2;
  -webkit-transition: all .2s ease-out;
  transition: all .2s ease-out;
  padding: 0;
  margin: 0; }
  button.back-to-top:hover {
    opacity: 0.7; }
  @media (max-width: 600px) {
    button.back-to-top {
      right: 5%; } }

.background--five button.back-to-top {
  border-color: white;
  color: white; }

