@mixin colorInverse {
  &:hover {
    color: $color-inverse;
  }
}

.bookmarks {
  position: fixed;
  top: 20%;
  left: 0;
  z-index: 10;
  width: 3em;

  @media (max-width: $screen-tiny) {
    display: none;
  }

  a {
    font-family: $font-header;
    font-size: 1.3em;
    font-weight: bold;
    text-align: right;
    line-height: 3em;
    padding: 0 1em;
    box-sizing: content-box;

    width: 8em;
    display: block;
    margin-bottom: 1em;
    margin-left: -8.5em;

    @media (max-width: $screen-small) {
      margin-left: -7.5em;
    }

    border-top-right-radius: $border-radius-large;
    border-bottom-right-radius: $border-radius-large;

    box-shadow: 1px 3px 5px $shadow-color;
    text-decoration: none;

    transition: all .2s ease-in;

    &:link, &:visited {
      color: transparent;
      border: none;
    }

    &:hover, &:focus {
      margin-left: 0;
      color: $color-primary;
    }

    &:nth-child(1) {
      background: $color-one;

      @include colorInverse;
    }

    &:nth-child(2) {
      background: $color-two;

      @include colorInverse;
    }

    &:nth-child(3) {
      background: $color-three;
    }

    &:nth-child(4) {
      background: $color-four;

      @include colorInverse;
    }

    &:nth-child(5) {
      background: $color-primary;

      @include colorInverse;
    }
  }
}