.contact.contact__icons {
  text-align: right;
  padding: 1em 10% 0;

  .links {
    height: 4em;
    display: inline-flex;
    align-items: center;
    flex-direction: row;
  }

  a {
    &:link, &:visited {
      color: $color-primary;
      border: none;
      margin: 4px;
    }

    &:hover {
      color: $color-secondary;

      svg {
        height: 3.2em;
      }
    }

    svg {
      width: 2.8em;
      height: 2.6em;
      transition: all .2s ease-out;
    }

    span {
      display: none;
    }

  }
}