@import "variables";

@mixin connector {
  &:after {
    content: "";
    display: block;

    background: $color-primary;

    width: 8px;
    height: 4em;
    margin: 0 auto;
  }
}

.content__blocks {
  display: inline-block;
  margin: 0 auto;
  width: 58em;
  max-width: 100%;

  @media (max-width: $screen-tiny) {
    width: auto;
  }
}

.block {
  &__content {
    width: 40em;
    max-width: 100%;

    background: $color-inverse;
    padding: 2em 8em 5em;
    border-radius: $border-radius-small;
    box-sizing: content-box;

    box-shadow: 0 0 20px $shadow-color;

    @media (max-width: $screen-small) {
      padding: 2em 10% 4em;
      width: auto;
    }

    @media (max-width: $screen-tiny) {
      padding: 1em 2em 3em;

      border-left: 0 !important;
      border-right: 0 !important;
    }
  }
}

.block#experience {
  .block__content {
    border: $color-one solid 15px;
  }

  @include connector;
}

.block#education {
  .block__content {
    border: $color-two solid 15px;
  }

  @include connector;
}

.block#freetime {
  .block__content {
    border: $color-three solid 15px;
  }

  @include connector;
}

.block#projects {
  .block__content {
    border: $color-four solid 15px;
  }
}

@media (min-width: $screen-large + 1) {
  .block#experience {
    .block__content {
      margin-left: -8em;
    }
  }

  .block#education {
    .block__content {
      margin-left: 8em;
    }
  }

  .block#freetime {
    .block__content {
      margin-left: -14em;
    }
  }

  .block#projects {
    .block__content {
      margin-left: -3em;
    }
  }
}

@media (min-width: $screen-medium + 1) {
  .block#experience {
    .block__content {
      margin-left: -4vw;
    }
  }

  .block#education {
    .block__content {
      margin-left: 6vw;
    }
  }

  .block#freetime {
    .block__content {
      margin-left: -6vw;
    }
  }

  .block#projects {
    .block__content {
      margin-left: 4vw;
    }
  }
}

/* Experience Block */
#experience {
  .experience__filter {
    border: 2px solid $color-secondary;
    padding: 2em 1em;
    margin-top: 4em;
    border-radius: $border-radius-small;

    @media (max-width: $screen-tiny) {
      border: 0;
      padding: 0;
      margin-top: 3em;
    }

    .filter__container {
      margin-bottom: 1em;

      input {
        display: none;
      }

      label {
        font-size: 80%;
        text-transform: uppercase;
        white-space: nowrap;

        border: 1px solid $color-secondary;
        border-radius: 2px;
        padding: 4px 6px;
        margin: 6px 4px;
        display: inline-block;
        cursor: pointer;

        transition: all .1s ease-in;

        @media (max-width: $screen-tiny) {
          font-size: 100%;
        }

        svg {
          font-size: 1.4em;
          margin: -.1em 4px -.1em -.1em;
        }

        &.is--selected {
          color: $color-inverse;
          background: linear-gradient(180deg, $color-secondary, $color-primary);
        }

        &:hover:not(.is--selected) {
          color: $color-inverse;
          background: $color-secondary;
          border-color: $color-primary;
        }
      }
    }

    .tags {
      display: flex;
      flex-direction: row;
      flex-wrap: wrap;

      .tag {
        font-size: 90%;

        background: $color-primary;
        color: $color-inverse;
        padding: 2px 8px;
        margin: 6px 3px;
        border-radius: $border-radius-small;

        display: flex;
        flex-direction: row;
        align-items: center;

        @media (max-width: $screen-tiny) {
          font-size: 100%;
        }
      }

      .tag__level {
        height: 1.2em;
        margin-left: .3em;
        width: (9*5px);
        background: $color-secondary;
        border: 1px solid $color-primary;
      }

      .level__indicator {
        display: inline-block;
        background: linear-gradient(90deg, lighten($color-detail, 10%) 0%, $color-detail 90%);
        width: 6px;
        height: 100%;
        margin-right: 3px;

        border-radius: 1px;

        &:last-child {
          margin-right: 0;
        }
      }
    }
  }
}

/* Education Block */
#education {
  .info__block {
    display: flex;
    flex-direction: row;

    .image {
      margin: 1.5em 1em 0 0;

      @media (max-width: $screen-tiny) {
        display: none;
      }

      img {
        height: 12em;
      }
    }

    p {
      margin: 0.5em 0;
    }
  }
}

/* Freetime Block */
#freetime {
  .image__gallery {
    display: flex;
    flex-direction: row;

    .image {
      width: 33%;
      margin: 5px;

      &:first-child {
        margin-left: 0;
      }

      &last-child {
        margin-right: 0;
      }

      img {
        width: 100%;
        cursor: zoom-in;
      }
    }
  }

  .image__lightbox {
    position: fixed;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;

    background: rgba(0, 0, 0, 0.85);
    text-align: center;

    z-index: 100;
    display: none;

    cursor: zoom-out;

    .image {
      margin: 3vh auto;
      max-width: 100%;
      max-height: 100%;
      display: inline-block;
      border: 2px solid $color-primary;
      overflow: hidden;

      @media (max-width: $screen-tiny) {
        padding: 1em;
        margin: 5vw auto;
      }

      img {
        display: block;
        max-width: 90vw;
        max-height: 90vh;

        padding: 2em 3em;
        background: $color-inverse;

        @media (max-width: $screen-tiny) {
          padding: 1em;
        }
      }
    }
  }
}