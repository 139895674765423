.background--four footer {
  color: $color-primary;
}

footer {
  color: $color-inverse;
  text-align: center;

  width: 42em;
  max-width: 100%;
  padding: 4em 2em 0;

  .contact.contact__links {
    margin: 1em 0 4em;

    .links {
      height: 4em;
      display: flex;
      flex-direction: row;
      align-items: center;
      flex-wrap: wrap;
    }

    .link {
      flex: 1;
      display: block;
      white-space: nowrap;
      padding: 5px;
    }

    a {
      &:link, &:visited {
        font-size: 1.2em;
        margin: -.2em;
        color: inherit;
        text-decoration: none;

        display: inline-block;
        border-bottom: 3px solid transparent;
        transition: all .3s ease;
      }

      @media (min-width: $screen-tiny + 1) {
        &:hover {
          font-size: 1.4em;
          border-color: $color-secondary;
        }
      }

      svg {
        margin-right: .3em;
      }
    }
  }
}