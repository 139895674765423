@import url('https://fonts.googleapis.com/css?family=Cinzel+Decorative|Montserrat|Share+Tech+Mono&display=swap');

$font-header: "Cinzel Decorative", serif;
$font-body: "Montserrat", sans-serif;
$font-monospace: "Share Tech Mono", monospace;

.color--primary {
  color: $color-primary;
}

.color--secondary {
  color: $color-secondary;
}

.color-inverse {
  color: $color-inverse;
}

.color--one {
  color: $color-one;
}

.color--two {
  color: $color-two;
}

.color--three {
  color: $color-three;
}

.color--four {
  color: $color-four;
}

.align--left {
  text-align: left;
}

.align--right {
  text-align: right;
}

.align--center {
  text-align: center;
}