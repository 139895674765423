@mixin input {
  font-family: $font-body;
  font-size: 12px;
  line-height: 1.6;

  background: $color-primary;
  color: $color-inverse;

  width: 100%;
  border: 2px solid $color-secondary;
  border-radius: $border-radius-small;

  margin-top: .4em;

  &:focus {
    border-color: $color-highlight-one;
  }
}

form {
  label {
    font-family: $font-header;
    font-size: 1.2em;
    font-weight: bold;

    margin: 1em 0;
    display: block;
  }

  input {
    padding: .8em 1em;

    @include input;
  }

  textarea {
    height: 20em;
    padding: .8em 1em;

    resize: none;

    @include input;
  }
}

button {
  color: $color-inverse;
  font-family: $font-body;
  text-transform: uppercase;

  background: $color-primary;
  border: 2px solid $color-secondary;
  border-radius: $border-radius-small;

  cursor: pointer;
  padding: 1em 2em;
  transition: all .3s ease-out;

  &:hover {
    color: $color-highlight-one;
    border-color: $color-highlight-one;
  }

  &.button--link {
    color: $color-highlight-two;
    font-weight: bold;
    text-transform: unset;

    background: transparent;
    padding: 3px 2px;
    border-radius: 0;
    border: 0;

    &:hover {
      color: $color-highlight-one;
    }
  }

  svg {
    margin-right: 4px;
  }
}