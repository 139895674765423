@import "variables";
@import "fonts";
@import "forms";
@import "blocks";
@import "bookmarks";
@import "footer";
@import "contact-icons";

@mixin background($color) {
  background: linear-gradient(180deg, $color, darken($color, 12%));
  background-size: cover;
  background-attachment: fixed;
}

* {
  box-sizing: border-box;
}

html, body {
  margin: 0;
  padding: 0;
  scroll-behavior: smooth;
}

body {
  color: $color-primary;
  font-family: $font-body;
  font-size: 14px;
  line-height: 1.8;

  @media (max-width: $screen-small) {
    font-size: 12px;
  }

  &.background--one {
    @include background($color-one);
  }

  &.background--two {
    @include background($color-two);
  }

  &.background--three {
    @include background($color-three);
  }

  &.background--four {
    @include background($color-inverse);
  }

  &.background--five {
    @include background($color-secondary);
  }
}

h1, h2, h3, h4, h5, h6 {
  font-family: $font-header;
  line-height: 1.4;
  text-shadow: $text-shadow;
}

h1 {
  font-size: 3.5em;
}

h2 {
  font-size: 2.8em;
  margin: .8em 0 .5em;
}

h3 {
  font-size: 1.4em;
}

p, ul {
  line-height: 2;
}

a {
  &:link, &:visited {
    color: $color-primary;
    text-decoration: none;
    border-bottom: 2px solid $color-highlight-two;
    transition: all .3s ease-out;
  }

  &:hover {
    border-color: $color-highlight-one;
  }
}

ul {
  margin: 2em 0;

  @media (max-width: $screen-tiny) {
    padding: 0 1em;
  }

  li {
    list-style: none;

    &:before {
      content: "\25CF";
      color:  $color-highlight-one;
      padding-right: 1.2em;

      @media (max-width: $screen-tiny) {
        padding-right: .8em;
      }
    }
  }
}

code {
  color: $color-highlight-one;
  font-family: $font-monospace;
}

header {
  color: $color-primary;
  text-align: center;

  width: 100%;
  margin-bottom: 2em;

  .header {
    display: inline-flex;
    flex-direction: row;

    @media (max-width: $screen-tiny) {
      transform: scale(0.8);
    }

    img {
      height: 12.5em;
      margin-top: 2em;
      margin-right: 1em;
    }

    #given-name {
      font-size: 2.3em;
      line-height: 1;

      display: block;
    }

    #last-name {
      display: block;
    }
  }

  .title {
    font-size: 1.6em;
    font-weight: bold;
    text-transform: uppercase;
    line-height: 1em;
    color: $color-secondary;
    margin: -0.5em 0 2em;

    @media (max-width: $screen-tiny) {
      transform: scale(0.8);
      margin-top: -1em;
    }
  }
}

.content {
  display: flex;
  align-items: center;
  flex-direction: column;

  background: transparent url("/images/dotted-background.svg") repeat-x;
  background-size: auto 90%;
  background-attachment: fixed;

  padding: 0 0 6em;
}

button.back-to-top {
  position: fixed;
  bottom: 3%;
  right: 2%;
  cursor: pointer;

  font-size: 2em;
  border: 3px solid $color-primary;
  background: rgba(255, 255, 255, 0.1);
  color: $color-primary;

  width: 2.5em;
  height: 2.5em;
  border-radius: 50%;

  opacity: 0.2;
  transition: all .2s ease-out;

  padding: 0;
  margin: 0;

  &:hover {
    opacity: 0.7;
  }

  @media (max-width: $screen-tiny) {
    right: 5%;
  }
}

.background--five button.back-to-top {
  border-color: $color-inverse;
  color: $color-inverse;
}